import React from 'react'
import Logo from '../../../asset/Images/Logo/logo_footer.png'
import Wrap from '../../../UI/LAYOUT/HOME/WRAP/Wrap'
import Typography from '../../../UI/ATOM/TEXT/HOME/Typography'
import Container from '../../../UI/LAYOUT/HOME/CONTAINER/Container'
import { useResize } from '../../../hooks/window/useResize'
import { FormattedMessage } from 'react-intl'
import FlexWrap from '../../../UI/LAYOUT/WRAP/FlexWrap'
import FlexColWrap from '../../../UI/LAYOUT/WRAP/FlexColWrap'

// const footer = [
//   { row: '주소', rowText: '서울시 중랑구 동일로 151길 13, 2F', col: 'E-mail', colText: 'tkw@aivill.co.kr' },
//   { row: 'Tel.', rowText: '+82. 2. 975. 1359', col: '사업자등록번호', colText: '830-88-02877' },
//   { row: '어플 사용 문의', colText: 'jjy@aivill.co.kr' },
//   { row: '사업 제휴 문의', colText: 'edward@aivill.co.kr' }
// ]

const Footer = () => {
  const mobile = useResize({ md: true, sm: true })

  const containerStyle = useResize({
    lg: 'bg-[#E8ECFA] text-[#191919]',
    md: 'bg-[#E8ECFA] text-[#191919]',
    sm: 'bg-[#E8ECFA] text-[#191919]',
  })

  const logoStyle = useResize({
    lg: 'mb-[20px]',
    md: 'text-center mb-[20px]',
    sm: 'text-center mb-[10px]',
  })

  const logoSize = useResize({
    sm: 'max-w-[280px] w-[200px] min-w-[160px]',
  })

  const flexWrapStyle = useResize({
    lg: 'gap-[10px]',
    md: 'gap-[10px] items-center',
    sm: 'flex-col gap-[10px]',
  })

  const flexColWrapStyle = useResize({
    md: 'mb-[10px] items-start',
    sm: 'mb-[10px] items-start',
  })

  const labelStyle = useResize({
    lg: { bold: 'bold', leading: '26px' },
    md: { bold: 'bold', leading: '26px' },
    sm: { variant: 'p2', bold: 'bold', leading: '20px' },
  })

  const contentStyle = useResize({
    lg: { leading: '26px' },
    md: { leading: '26px' },
    sm: { variant: 'p2', leading: '23px' },
  })

  return (
    <Container className={containerStyle}>
      {/* <Wrap> */}
      <Wrap size='lg'>
        <FlexColWrap className={flexColWrapStyle}>
          <div className={logoStyle}>
            <img
              src={Logo}
              className={logoSize}
              alt=''
            />
          </div>
          <FlexWrap className='gap-[10px] items-center'>
            <Typography {...labelStyle}>
              <FormattedMessage id='사업자 등록번호' />
            </Typography>
            <Typography {...contentStyle}>894-88-02212 / 830-88-02877</Typography>
          </FlexWrap>
          <FlexColWrap className='gap-[10px]'>
            {/* <FlexWrap className='gap-3'> */}
            <Typography {...labelStyle}>
              <a href='/policy/privacy'>
                <FormattedMessage id='개인정보처리방침' />
              </a>
            </Typography>
            {/* <Typography {...labelStyle}>
                <a href='/sportsent/notice'>
                  <FormattedMessage id='공지' />
                </a>
              </Typography> */}
            {/* </FlexWrap> */}
            <Typography {...contentStyle}>COPYRIGHT ⓒ 2023 AIVILL, SPORTSENT INC. ALL RIGHTS RESERVED.</Typography>
          </FlexColWrap>
        </FlexColWrap>

        {/* <div className='font-bold my-[5px]'>
          <a href='/policy/privacy'><FormattedMessage id='개인정보처리방침' /></a>
        </div>
        <Typography variant='p1'>
          COPYRIGHT ⓒ 2023 SPORTSENT INC. ALL RIGHTS RESERVD.
        </Typography> */}
      </Wrap>
    </Container>
  )
}

export default Footer
