import React from 'react'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../../UI/ATOM/BUTTON/PrimaryButton'

const CantAccessPage = () => {
  const navigate = useNavigate()

  return (
    <div className="w-[480px] h-[450px] p-10 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-[#fff] rounded-sm border-solid border-[1px] border-[#a9a9a9]">
      <div className="flex flex-col justify-center items-center">
        <div className="mt-[20px] mb-[20px]">
          <NotInterestedIcon className="w-[100px] h-[100px] text-[#2E5CFF]" />
        </div>
        <Typography variant="h2" className="mb-[30px] tracking-normal">
          <FormattedMessage id="현재 페이지에 접근 권한이 없습니다." />
        </Typography>
        <Typography className="text-center tracking-normal leading-7 mb-[40px]">
          <FormattedMessage
            id="방문하시려는 페이지는 권한이 있는 회원만 접근할 수 있습니다."
            values={{ br: (v) => <br /> }}
          />
          {/* <br></br>
          로그인 정보를 다시 확인해 주세요. */}
        </Typography>
        <div className="flex gap-[15px]">
          <PrimaryButton onClick={() => navigate('/')} variant="contained">
            <FormattedMessage id="홈으로" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default CantAccessPage
