import React from 'react'
import { useRecoilValue } from 'recoil'
import { Navigate, Outlet } from 'react-router-dom'
import { userRoleSelector } from '../../../recoil/atom/login/userInfoAtom'
import CantAccessPage from '../../../PAGES/COMMON/CantAccessPage'

const CheckIsLogin = () => {
  const accessToken = sessionStorage.getItem('access_token')

  const userRole = useRecoilValue(userRoleSelector)

  return !accessToken ? <Navigate to='/qr/login' /> : userRole !== 'KW' ? <CantAccessPage /> : <Outlet />
}

export default CheckIsLogin
