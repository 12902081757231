import React from 'react'
import FlexWrap from '../../../../../UI/LAYOUT/WRAP/FlexWrap'
import FlexColWrap from '../../../../../UI/LAYOUT/WRAP/FlexColWrap'
import { FormattedMessage } from 'react-intl'

const BillFooter = ({ data }) => {
  return (
    <FlexWrap className='flex-col w-full pt-[35px] pb-3 px-5 bg-[#e9e9e9]'>
      <FlexColWrap className='gap-[3px] text-[#888] pb-[20px] w-full'>
        <div className='flex flex-col text-start w-full gap-[2px]'>
          <div className='flex gap-[5px] items-center w-full'>
            <span className='text-sm  w-[20%]'>{`사업자명`}</span>
            <span className='text-sm'>{`${data?.BUSINESS_NAME ?? ''}`}</span>
          </div>
          <div className='flex gap-[5px] items-center w-full'>
            <span className='text-sm  w-[20%]'>{`사업자번호`}</span>
            <span className='text-sm'>{`${data?.BUSINESS_NUMBER ?? ''}`}</span>
          </div>
          <div className='flex gap-[5px] items-center w-full'>
            <span className='text-sm  w-[20%]'>{`대표자명`}</span>
            <span className='text-sm'>{`${data?.BUSINESS_REP_NAME ?? ''}`}</span>
          </div>
          <div className='flex gap-[5px] items-center w-full'>
            <span className='text-sm  w-[20%]'>{`유선번호`}</span>
            <span className='text-sm'>{`${data?.BUSINESS_PHONE_NUMBER ?? ''}`}</span>
          </div>
        </div>
      </FlexColWrap>

      <FlexWrap className='w-full justify-between text-[#777]'>
        <p className='font-bold'>태권월드</p>
        <p className='underline italic'>https://taekwonworld.net</p>
      </FlexWrap>
    </FlexWrap>
  )
}

export default BillFooter
